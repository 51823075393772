<template>
  <img
    class="animated-gift"
    data-t="animated-gift"
    src="./images/gift.png"
    alt="gift"
  />
</template>

<script setup lang="ts"></script>

<style scoped>
.animated-gift {
  display: flex;

  width: 32px;
  height: 32px;

  filter: drop-shadow(0 5px 3px rgb(0 0 0 / 28%));

  animation: rotate-animation 5s ease-in-out infinite;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  3% {
    transform: rotate(15deg);
  }

  6% {
    transform: rotate(0deg);
  }

  9% {
    transform: rotate(15deg);
  }

  12% {
    transform: rotate(0deg);
  }

  15% {
    transform: rotate(15deg);
  }

  18% {
    transform: rotate(0deg);
  }
}
</style>
